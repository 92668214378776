<template>
  <div class="legal-wrapper">
      <h3>{{ $t('LEGAL.TERMS_AND_CONDITIONS.title') }}</h3>
      <br><br>
      <div>

        ----- Not a real text --------<br><br><br>
        1. LEGAL NOTICE AND INFORMATION AND ACCEPTANCE

This notice and legal information (hereinafter, the “LEGAL NOTICE”) regulates the use of the Internet service www.ihacklabs.com, as well as any page that hangs from any of the ihacklabs.com subdomains (collectively referred to hereinafter as The “PORTAL”) and the services offered therein are subscribed, by IhackLabs LTD holder of said URL, established at 3-7 Temple Avenue Temple Chambers Room 38 London EC4Y 0HP United Kingdom (hereinafter “THE ENTITY”)

The use of the PORTAL attributes the user condition of (from now on, the “CUSTOMER and USER”) and implies the full and unreserved acceptance of each and every one of the provisions included in this LEGAL NOTICE in the version published by LA ENTITY at the moment when the user accesses the site. Consequently, the user must carefully read this LEGAL NOTICE on each of the occasions when he intends to use the site, as it may be modified.

Likewise, the use of the PORTAL is also subject to all notices, regulations of use and instructions, made known to the user by THE ENTITY that replaces, complete and modifies the present LEGAL NOTICE.

1.1 Identification of parts

The present general conditions of use of the URLs www.ihacklabs.com, as well as any page that hangs from any of the subdomains ihacklabs.com (jointly and hereinafter referred to as the PORTAL) and the services offered therein are subscribed by LA ENTITY holder of such URL, established at 3-7 Temple Avenue Temple Chambers Room 38 London EC4Y 0HP United Kingdom.

And, on the other hand, the CLIENT AND / OR USER, whose personal data are those that have been entered by the same in the contact messages exchanged with the ENTITY and / or through telephone calls and / or personal conversations with the workers Of the same and / or through the inscription, subscription and / or acquisition of any of the products or services of the ENTITY.

2. CONDITIONS OF ACCESS AND USE

THE ENTITY does not assume any responsibility derived from the incorrect, inappropriate or unlawful use of the information appeared in the PORTAL. As for the contents of the links contained in this PORTAL, THE ENTITY assumes no responsibility of any kind.

2.1. User Registration

In general, the provision of services does not require the previous subscription or registration of users.

2.2. Veracity of information

All information provided by the user through the services must be truthful. For these purposes, the user guarantees the authenticity of all the data that he/she communicates as a consequence of completing the necessary forms for the subscription of the services. (From now on “CUSTOMER and USER”) Likewise, it shall be the responsibility of the CUSTOMER and USER to keep all information provided to the ENTITY permanently updated in such a way as to respond, at any time, to the actual situation of the CUSTOMER AND / OR USER. In any case, the CUSTOMER and USER shall be solely responsible for any false or inaccurate statements made and for the damages caused to THE ENTITY or third parties for the information provided.

The CUSTOMER and USER agrees to use the services, and by the law, this LEGAL NOTICE, the particular conditions of certain services and other notices, regulations of use and instructions put to their knowledge, as well as with morality And generally accepted good customs and public order.

2.3. Use of the services offered by the anti-spamming policy of THE ENTITY

The CUSTOMER and USER agrees to refrain from:

Collect data for advertising purposes and to send advertising of any kind and communications for sale or other commercial purposes without their prior request or consent;
Forwarding any other unsolicited or previously consented messages to a plurality of persons;
send chains of unsolicited electronic messages or previously consented or mechanics;
put to disposition or n of third parties for any purpose, data collected from distribution lists or n.
Users or third parties harmed by the receipt of unsolicited messages addressed to a plurality of persons may communicate it to THE ENTITY by sending a message by e-mail to the account of info@ihacklabs.com

3. COPYRIGHT

THE ENTITY guarantees that the ENTITY has or has sufficient legal rights to the intellectual property rights of any content published or uploaded in the PORTAL, as well as courses, videos, laboratory content, exercises and material on computer security procedures ( Hereinafter referred to as “THE MATERIAL”) by the ENTITY (“ENTITY CONTENT”) and that the CONTENT OF THE ENTITY, including any use thereof by the CUSTOMER AND / OR USER as described herein Document, does not violate applicable law or the rights of third parties.

3.1 Prohibition of reproduction

This PORTAL and all its contents are covered by the legislation in force in the UK and internationally in the field of intellectual property. The user may not copy, download, publish, modify, transmit, reproduce, create works, distribute, sell, lease or otherwise exploit the materials included on this site, either partially or in its entirety, without the express authorization of THE ENTITY.

All the contents of the PORTAL, understood as merely informative texts, photographs, graphics, images, icons, technology, software, links and other audiovisual or sound content, as well as its graphic design and source codes, are the intellectual property of LA ENTITY or third parties, without being able to be understood as being assigned to the CLIENT AND / OR USER any of the exploitation rights over them.

The trademarks, trade names or distinctive signs are owned by THE ENTITY, without it being understood that the free access grants other rights or licenses for their reproduction and/or distribution, even quoting its source, without the express authorization of THE ENTITY.

More information: https://www.gov.uk/government/publications/intellectual-property-offences

3. RESPONSIBILITY RULES

3.1. Responsibility for the use of the PORTAL

The CUSTOMER AND / OR USER is solely responsible for any infractions that may be incurred or any damages that may be caused by the use of the PORTAL, leaving the owner of the PORTAL, its partners, group companies, employees, employees and representatives , Exempt from any kind of liability that could be derived by the actions of the CUSTOMER AND / OR USER.

The owner of the PORTAL and THE ENTITY shall use all reasonable efforts and means to provide updated and reliable information in the PORTAL, however, the owner of the PORTAL and THE ENTITY does not assume any guarantee regarding the absence of errors, or possible inaccuracies And/or omissions in any of the contents accessible through the PORTAL.

The CLIENT and USER is solely responsible for any claim or legal action, judicial or extrajudicial, initiated by third parties against the owner of the PORTAL and/or THE ENTITY based on the use by the CLIENT AND / OR USER of the PORTAL. In its case, the CUSTOMER and USER will assume all expenses, costs and indemnities to be surrendered to the owner of the Website in connection with such claims or legal actions.

3.2. Responsibility for the operation of the PORTAL

The owner of the PORTAL and/or THE ENTITY excludes any liability that may arise from interferences, omissions, interruptions, computer viruses, telephone breakdowns or disconnections in the operation of the electronic system, motivated by causes beyond the holder of the PORTAL and/or LA ENTITY.

In addition, PORTAL and / or THE ENTITY also excludes any liability that could be caused by delays or blockages in the operational operation of this electronic system caused by deficiencies or overload on telephone lines or on the Internet, as well as damages caused by third parties Through illegitimate intrusions outside the control of the owner of the PORTAL and / or THE ENTITY.

The owner of the PORTAL and / or THE ENTITY is authorised to temporarily suspend, without prior notice, the accessibility to the PORTAL in connection with maintenance, repair, update or improvement operations.

3.3. Liability for links

The links or links contained in the PORTAL may lead to the CLIENT AND / OR USER to other websites managed by third parties.

The owner of the PORTAL and / or THE ENTITY declines any responsibility regarding the information that is outside the PORTAL, since the function of the links that appear is only to inform the CLIENT AND / OR USER about the existence of other sources of Information on a specific topic.

3.4 Exclusion of guarantees and liability

The owner of the PORTAL and / or THE ENTITY is exempt from all responsibility for the correct functioning of such links, the result obtained through said links, the veracity and legality of the content or information that can be accessed, as well as The damages that may suffer the CUSTOMER AND / OR USER by virtue of the information found in the linked PORTAL.

IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DAMAGES OF ANY NATURE WHICH COULD RESULT IN, INCLUDING: ERRORS OR OMISSIONS IN THE CONTENTS, LACK OF AVAILABILITY OF THE PORTAL OR THE TRANSMISSION OF VIRUSES, The contents, despite having adopted all the necessary technological measures to avoid it.

THE ENTITY reserves the right to make any modifications that it deems appropriate in its portal without prior notice, being able to change, suppress or add both the contents and services provided through it and the way in which they are presented or located In its portal.

4. PRICES

The applicable prices are those indicated on the website, except typographical error, on the date of contracting the service. In each of the products or services offered will be informed if the price includes or not VAT, and / or if the product or service, if any, is exempt from it. In the case of doubt or omission express, it will be understood that the price does not include VAT.

THE ENTITY reserves the right to make, at any time and without prior notice, the modifications that it deems appropriate to the prices, discounts or promotions offered, as well as of the CONTENTS of the portal, being able to update, even daily, products and prices according to Of the novelties, discounts, promotions, offers and variation of the characteristics of the products and services.

The services contracted through the PORTAL of iHacklabs will be invoiced through CYBERRANGE SL company located in Spain Avenida de Reina Victoria 7E Madrid 28003 with NIF B88037668. This company is the parent and owner of iHackLabs. All prices include VAT Spanish to be constituted in Spain, VAT applicable by the ENTITY is cross-border VAT.

4.1 Returns / Cancellation of service

All purchases and services are final and all fees paid by the CUSTOMER AND / OR USER. Are not refundable. The CLIENT AND / OR USER is not entitled to the partial or total refund after the purchase process is completed.

IHACKLABS reserves the right to cancel unlimited access for members of the ICPP + course with pre-notification of fifteen calendar days, at no time and under no circumstances will the course be refundable.

5. PRIVACY

Our privacy policy describes how we collect, store or use the information we collect through the different services or pages available in the PORTAL. It is important to understand what information we collect and how we use since access to this site constitutes acceptance of our privacy policy.

Updated to May 25, 2.018.

DATA FROM
PURPOSES OF DATA PROCESSING
DURATION AND CONSERVATION OF TREATMENT
LEGITIMATION FOR DATA PROCESSING
RECIPIENTS
RIGHTS OF THE INTERESTED
SECURITY OF DATA
MORE INFORMATION

Responsible (of the treatment)

Reference Number: ZA441416

Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF
T. 0303 123 1113 F. 01625 524510
www.ico.org.uk

CyberRange SL

B88037668

Avenida Reina Victoria 7E 28003 Madrid

Email: info@ihacklabs.com

Email: info@ihacklabs.com

Commercial name.- IHACKLABS

Activity. CONSULTING AND TECHNOLOGY SERVICES

Purposes (of the treatment)

Manage your order or service and perform the service contracted by the client, billing and
Contact in your case with the sender who asks us for information about our products, answer your request
Control and monitor our portfolio of clients and potential clients, suppliers,
Control and manage the user’s registration on our website with all its
Send publicity about new products already existing or new ones that are incorporated, courses and events in the process of continuous improvement of our services and aimed at our customers as well as potential customers and suppliers.

Duration of processing your data

Your data will be kept in the Company during the time that the commercial and contractual relationship you have with us is maintained.

For the billing and collection of services and once the contracted service is completed, the data provided will be kept for 1 year.

Once the relationship is finished we can continue to send you advertising of our products and services indefinitely until you express your desire to delete them.

The data for advertising shipments on our products and services for which you have shown interest will be preserved indefinitely until you expressly express your willingness to stop receiving them.

The quotes or information about our products that you request will be kept for the period strictly necessary to respond to your request and never more than 1 year. However, you can give us your consent so that we can continue sending you advertising and promotions, events related to the products. for which you requested information.

For the case of having consented to the sending of advertisements about our products and services we will keep them as a potential client until you express your desire to delete them.

Legitimation (of treatment)

The recipient of your data is solely and exclusively the Company, the person responsible for the treatment.

Your strictly necessary data will be transferred to the financial entities for the purpose of charging or billing the services.

The data will not be transferred to third parties, unless legal provision or express consent of the interested party under contract, and only in the cases provided by law and for the purposes expressly established in the law.

There is no forecast of Transfers to third countries

Rights (of interested persons)

Access that consists in knowing if we are treating your data and Rectification that consists in rectifying some erroneous or inaccurate data that you can Deletion that consists of requesting the elimination of your data when you understand that they are no longer necessary for the purposes for which you consent, leaving safe the legal duty of conservation by our company and ensuring your blockade for legal time

Opposition in the case of having given their consent for a specific purpose and for personal reasons and which consists of requesting that their data be left untreated except for compelling legitimate reasons, legal duty or the exercise of defense of possible claims, we must continue to treat them, proceeding to its Of limitation of the treatment.- You can designate for conscientious purposes your data are still being treated and for which you revoke your consent, leaving the legal duty to conserve the data and for the legally established time of our company safe and secure your blockade by the established legal time.

Right to the portability of the data.- You have the right to ask who you want to send your information to.
You can exercise your rights through the means of contact that we indicate in the section of the person responsible for the treatment. Email or postcard of your choice. For your exercise it will be necessary and essential that we can identify you with a photocopy of your D.N.I.

The exercise of your rights will be answered by the Company within a period of one month and will be motivated, both in the case of being rejected and attended and in any case the context, nature of the personal data, its purpose will be taken into account and its legal basis.

In addition, we inform you that in the face of any violation of your rights and if by the means we put at your disposal you have not received a response to your request or you can directly submit your claim to any competent body or to the Spanish Agency for the Protection of Data www.agpd.es

Security of your data

In addition, to the extent of the state of the art and means within our reach within the principle of proportionality and under the principles of LOYALTY, TRANSPARENCY, SECURITY AND CONFIDENTIALITY, we guarantee the establishment of security measures in the processing of your data.

Send commercial, advertising or promotional communications.

For him sent of promotional communications and offer of products related to our activity is Law 34/2002 of July 11 of Services of the Information Society and electronic commerce.

Potential customers who access our website and ask for information about our products should check a box to the effect for which they expressly authorize IF I WISH TO RECEIVE PUBLICITY OR COMMUNICATIONS

PROMOTIONAL on the products or new products of IHackLabs.

This authorization will not be necessary for clients when there is a contractual relationship and the advertising sent to them is related to the contracted or similar products.

In all communications, the interested party should be offered the possibility of opposing the processing by means of a simple and free procedure both at the time of data collection and in each of the commercial communications addressed to him.

All the above in accordance with Article 21 of the LSSI.

More information

The control by the individuals of their own personal data is recognized and is configured as a Right of the interested party and an obligation of protection of their data by the Company. The protection of natural persons in relation to the processing of data is a fundamental and very personal right.

Regulation (EU) 2016/679 of the European Parliament and Council of 27.04.2016 applies in the field of microenterprise with less than 250 employees and on the GENERAL BASE OF THE PRINCIPLE OF PROPORTIONALITY, as well as all the

complementary or protective legislation of fundamental rights.

The consent of the interested party for the treatment of their data for a specific purpose must be expressed by an affirmative act of acceptance that is clear and informed that expresses a free will, express and in its specific case for each purpose of treatment.

For informed consent, we have established a system that adapts to the informed consent of the interested party and establishes as a general rule the provision of services to clients and communications to potential clients.

In communications with the interested party, we are obliged to check his / her own right at the time of giving his / her consent in the affirmative. YES / NO for each purpose for which we obtain your consent. It will be applied as of May 25, 2.018 in the case of new customers or new potential customers. In the case of marking YES, you consent to the data processing. If you do not mark anything or if you do not mark, you will be denying your consent and therefore we will not be able to process your data. Typically, consent has been given electronically.

The consents obtained up to the mandatory date of the RGPD have been obtained in a lawful manner according to the LOPD and LSSI, therefore they are all valid in accordance with the 171th recital of the RGPD (171) The Directive 95/46 / CE must be repealed by this Regulation. Any treatment already begun on the date of application of this Regulation must comply with this Regulation within a period of two years from the date of its entry into force. When the treatment is based on consent in accordance with Directive 95/46 / EC, it is not necessary for the interested party to give his or her consent again if the form in which the consent was given conforms to the conditions of this Regulation, in order that the person in charge can continue this treatment after the date of application of this Regulation. The decisions of the Commission and the authorizations of the control authorities based on Directive 95/46 / EC remain in force until they are modified, replaced or repealed.

5.1 Third parties

This site measures traffic with different solutions to analyse what happens on our pages. We currently use the following solution to measure the traffic of the PORTAL

You can see more information about the privacy policy at:

Automattic / Quantcast (JetPack Stats): Privacy Automattic and Quantcast .
Google (Analytics): Privacy Google Analytics
6. USE OF COOKIES

The ENTIDAD reserves the right to use cookies in the PORTAL. However, Users are advised of the possibility of disabling their use in their browser.

The ENTITY complies with the European cookie regulations subject to the following laws and regulations.

These obligations are described in Regulation (EC) No 45/2001 pdf [120 KB] of the European Parliament and of the Council of 18 December 2000 and Directive 2002/58 / EC of the European Parliament and of the Council of 12 July 2002 concerning the processing of personal data and the protection of privacy in the electronic communications sector (Directive on privacy and electronic communications)

More information http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm

Http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm#section_3

6.1 Disabling Cookies

The user can at any time – choose which cookies he wants to work on this website through the browser configuration; for example:

Chrome, from http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
Explorer, from http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
Firefox, from http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web
Safari, from http://support.apple.com/kb/ph5042
7. EFFECTIVENESS

THE ENTITY may modify the terms and conditions set forth herein, in whole or in part, by publishing any changes in the same manner as these general conditions appear. The temporary validity of these general conditions, therefore, coincides with the time of their exposure, until they are totally or partially modified, at which time the modified general conditions will become effective.

8. APPLICABLE LEGISLATION AND JURISDICTIONAL COMPETITION

The ENTITY is incorporated and registered with HM Revenue & Custom, Registrar of Companies for England and Wales, Company Number 10246354.

The British law regulates these terms and conditions and the CUSTOMER AND / OR USER agrees that the British courts shall have exclusive jurisdiction to determine any matter or dispute in relation to or arise out of the ENTITY and these terms and conditions, expressly waiving the CUSTOMER AND / OR USER to any other jurisdiction that may correspond.

If you choose to access the information in this PORTAL, it is your responsibility to comply with applicable local, national or international laws and any use of this site outside of the United Kingdom. For guidance regarding your specific situation, please contact your legal counsel.

More information https://www.gov.uk/
      </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditionsPage'
}
</script>

<style lang="scss" scoped>

.legal-wrapper {
  padding-top: 2rem;
  width: 70%;
  margin: 0 auto;
}
</style>
